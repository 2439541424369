import axios from 'axios'

const request = axios.create({
 //  baseURL: 'http://localhost',
  baseURL: 'http://zhaohua.gfkjzjs.top/',
  timeout: 5000,
})

request.interceptors.response.use((res) => {
  return res.data
})

export const UPLOAD_IMG_URL = 'http://zhaohua.gfkjzjs.top/upload/img'

export const configselect = () =>
  request({
    url: '/config',
    method: 'get',
  })

export const configsave = (data) =>
  request({
    url: '/config/update',
    method: 'post',
    data,
  })

export const activitycreate = (data) =>
  request({
    url: '/activity/create',
    method: 'post',
    data,
  })

export const activityupdate = (data) =>
  request({
    url: '/activity/update',
    method: 'post',
    data,
  })

export const activityselect = () =>
  request({
    url: '/activity/all',
    method: 'get',
  })

export const activitydel = (data) =>
  request({
    url: '/activity/del',
    method: 'post',
    data,
  })
export const activityuse = (data) =>
  request({
    url: '/activity/use',
    method: 'post',
    data,
  })
export const schedulenow = (data) =>
  request({
    url: '/schedule/' + data,
    method: 'get',
  })
export const schedulesave = (id, data) =>
  request({
    url: '/schedule/save/' + id,
    method: 'post',
    data,
  })
export const personGroupid = (id) =>
  request({
    url: '/personGroup/' + id,
    method: 'get',
  })
export const personGroupsave = (data, id) =>
  request({
    url: '/personGroup/save/' + id,
    method: 'post',
    data,
  })
export const pointid = (id) =>
  request({
    url: '/point/' + id,
    method: 'get',
  })
export const pointsave = (id, data) =>
  request({
    url: '/point/save/' + id,
    method: 'post',
    data,
  })
export const schedulemealget = (id) =>
  request({
    url: '/schedule/mealget/' + id,
    method: 'get',
  })
export const mealsaveactivityId = (id, data) =>
  request({
    url: '/schedule/meal/save/' + id,
    method: 'post',
    data,
  })
export const prompt = (id) =>
  request({
    url: '/prompt/list/' + id,
    method: 'get',
  })
export const promptsave = (id, data) =>
  request({
    url: '/prompt/save/' + id,
    method: 'post',
    data,
  })

export const lizhouselect = () =>
  request({
    url: '/lizhou',
    method: 'get',
  })

export const lizhousave = (data) =>
  request({
    url: '/lizhou/save',
    method: 'post',
    data,
  })

export const jinquselect = () =>
  request({
    url: '/jinqu',
    method: 'get',
  })

export const jinqusave = (data) =>
  request({
    url: '/jinqu/save',
    method: 'post',
    data,
  })

export const pwdadd = (data) =>
  request({
    url: '/pwd/add',
    method: 'post',
    data,
  })

export const pwdall = (data) =>
  request({
    url: '/pwd/all',
    method: 'post',
    data,
  })

export const pwddel = (data) =>
  request({
    url: '/pwd/del',
    method: 'post',
    data,
  })

